import React, { useState, useEffect } from "react";
import "../styles/CustomStyles/Navigation.css";

export const Navigation = (props) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

  const toggleMobileNav = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };

  const handleNavLinkClick = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      let scrollPosition = window.scrollY + 200;

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
          const sectionId = section.getAttribute("id");
          const correspondingNavItem = document.querySelector(`.navbar a[href="#${sectionId}"]`);

          if (correspondingNavItem) {
            document.querySelectorAll(".navbar a").forEach((item) => {
              item.classList.remove("active");
            });

            correspondingNavItem.classList.add("active");
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header id="header" className="header fixed-top">
      <nav
        id="navbar"
        className={`navbar align-items-center ${
          isMobile ? "justify-content-start" : "justify-content-center"
        } ${isMobileNavOpen ? "active" : ""}`}
      >
        <ul>
          <li>
            <a
              className="nav-link scrollto"
              href="#home"
              onClick={handleNavLinkClick}
            >
              Home
            </a>
          </li>
          <li>
            <a
              className="nav-link scrollto"
              href="#features"
              onClick={handleNavLinkClick}
            >
              Features
            </a>
          </li>
          <li>
            <a
              className="nav-link scrollto logo"
              href="#home"
              onClick={handleNavLinkClick}
            >
              <img src={"/dermatech-logo.png"} alt="" />
            </a>
          </li>
          <li>
            <a
              className="nav-link scrollto"
              href="#pricing"
              onClick={handleNavLinkClick}
            >
              Pricing
            </a>
          </li>
          <li>
            <a
              className="nav-link scrollto"
              href="#about"
              onClick={handleNavLinkClick}
            >
              About
            </a>
          </li>
        </ul>
        <div className="logo-mobile-container">
          <i
            className="logo"
            href="#home"
            onClick={handleNavLinkClick}
          >
            <div className={`mobile-img-container ${isMobile ? "show-mobile" : ""}`}>
              <img className="mobile-nav-img" src={"/dermatech-logo.png"} alt="" />
            </div>
          </i>
          <i
            className={`bi bi-list mobile-nav-toggle ${
              isMobileNavOpen ? "bi-x" : ""
            }`}
            onClick={toggleMobileNav}
          ></i>
        </div>
      </nav>
      {isMobileNavOpen && (
        <div className="navbar-mobile">
          <div className="logo-mobile-container">
            <i
              className={`bi bi-list mobile-nav-toggle ${
                isMobileNavOpen ? "bi-x" : ""
              }`}
              onClick={toggleMobileNav}
            ></i>
          </div>
          {/* Mobile navigation content */}
          <ul>
            <li>
              <a
                className="nav-link scrollto"
                href="#top"
                onClick={handleNavLinkClick}
              >
                Home
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                href="#features"
                onClick={handleNavLinkClick}
              >
                Features
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                href="#pricing"
                onClick={handleNavLinkClick}
              >
                Pricing
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                href="#about"
                onClick={handleNavLinkClick}
              >
                About
              </a>
            </li>
          </ul>
        </div>
      )}
    </header>
  );
};